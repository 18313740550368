<template>
  <v-container class="general">
    <PageTitle :title="'Admin panel routes'">
      <template slot="actions">
        <v-text-field
          v-model="searchInput"
          light
          solo
          class="search-input body-1"
          label="Type to filter routes"
          hide-details
        >
          <template slot="append">
            <font-awesome-icon
              :icon="['fas', 'search']"
              :style="{ fontSize: '20px' }"
              class="ml-1 theme-accent"
            ></font-awesome-icon>
          </template>
        </v-text-field>
      </template>
    </PageTitle>
    <v-layout class="row wrap">
      <v-flex class="xs12">
        <div v-if="!loading && !filteredRoutes.length">Nothing found</div>
        <v-card
          v-if="filteredRoutes.length"
          class="px-4 pt-3 pb-2 border-radius"
        >
          <v-layout class="row wrap align-center">
            <template v-for="(route, i) in filteredRoutes">
              <v-flex
                :key="`route-${i}`"
                v-if="
                  (route &&
                    route.meta &&
                    route.meta.permissions &&
                    route.meta.permissions.length) ||
                  (route &&
                    route.meta &&
                    route.meta.groupPolicies &&
                    route.meta.groupPolicies.length)
                "
                class="xs12 mb-2"
              >
                <v-divider v-if="i !== 0" class="mb-2"></v-divider>
                {{ route.name.toUpperCase().replaceAll("_", " ") }}
                <div class="caption">
                  <span class="grey--text">Path:</span>
                  {{ route.path }}
                </div>
                <div
                  class="caption"
                  v-if="
                    route &&
                    route.meta &&
                    route.meta.permissions &&
                    route.meta.permissions.length
                  "
                >
                  <span class="grey--text">Permission:</span>
                </div>
                <div
                  class="caption"
                  v-if="
                    route &&
                    route.meta &&
                    route.meta.groupPolicies &&
                    route.meta.groupPolicies.length
                  "
                >
                  <span class="grey--text">Policies:</span>
                </div>
              </v-flex>
            </template>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex v-if="totalPages > 1" class="xs12 text-xs-center pt-4">
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="totalVisible"
          @input="listRoutes"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    loading: false,
    searchInput: "",
    totalPages: 0,
    totalVisible: 5,
    perPage: 1000,
    page: 1,
  }),
  computed: {
    ...mapGetters(["currentUser"]),
    filteredRoutes() {
      return this.routes.filter((el) =>
        el.name.toUpperCase().includes(this.searchInput.toUpperCase()),
      );
    },
    routes() {
      return this.$router.options.routes;
    },
  },
  mounted() {
    const isSuperAdmin = this.currentUser.roles.includes("admin");
    if (!isSuperAdmin) this.$router.push({ name: "home" });
    this.totalPages = Math.max(this.routes.length / 100, 1);
  },
};
</script>

<style scoped lang="scss"></style>
