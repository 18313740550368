var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"general"},[_c('PageTitle',{attrs:{"title":'Admin panel routes'}},[_c('template',{slot:"actions"},[_c('v-text-field',{staticClass:"search-input body-1",attrs:{"light":"","solo":"","label":"Type to filter routes","hide-details":""},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}},[_c('template',{slot:"append"},[_c('font-awesome-icon',{staticClass:"ml-1 theme-accent",style:({ fontSize: '20px' }),attrs:{"icon":['fas', 'search']}})],1)],2)],1)],2),_c('v-layout',{staticClass:"row wrap"},[_c('v-flex',{staticClass:"xs12"},[(!_vm.loading && !_vm.filteredRoutes.length)?_c('div',[_vm._v("Nothing found")]):_vm._e(),(_vm.filteredRoutes.length)?_c('v-card',{staticClass:"px-4 pt-3 pb-2 border-radius"},[_c('v-layout',{staticClass:"row wrap align-center"},[_vm._l((_vm.filteredRoutes),function(route,i){return [(
                (route &&
                  route.meta &&
                  route.meta.permissions &&
                  route.meta.permissions.length) ||
                (route &&
                  route.meta &&
                  route.meta.groupPolicies &&
                  route.meta.groupPolicies.length)
              )?_c('v-flex',{key:`route-${i}`,staticClass:"xs12 mb-2"},[(i !== 0)?_c('v-divider',{staticClass:"mb-2"}):_vm._e(),_vm._v(" "+_vm._s(route.name.toUpperCase().replaceAll("_", " "))+" "),_c('div',{staticClass:"caption"},[_c('span',{staticClass:"grey--text"},[_vm._v("Path:")]),_vm._v(" "+_vm._s(route.path)+" ")]),(
                  route &&
                  route.meta &&
                  route.meta.permissions &&
                  route.meta.permissions.length
                )?_c('div',{staticClass:"caption"},[_c('span',{staticClass:"grey--text"},[_vm._v("Permission:")])]):_vm._e(),(
                  route &&
                  route.meta &&
                  route.meta.groupPolicies &&
                  route.meta.groupPolicies.length
                )?_c('div',{staticClass:"caption"},[_c('span',{staticClass:"grey--text"},[_vm._v("Policies:")])]):_vm._e()],1):_vm._e()]})],2)],1):_vm._e()],1),(_vm.totalPages > 1)?_c('v-flex',{staticClass:"xs12 text-xs-center pt-4"},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":_vm.totalVisible},on:{"input":_vm.listRoutes},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }